@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,700,900);
/*==========================================================
  BODY 
=========================================================*/
body {
  margin: 0;
  font-family: BlinkMacSystemFont, Helvetica Neue, sans-serif; }

html,
body {
  overflow-x: hidden; }

/*==========================================================
  NAVIGATION
==========================================================*/
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background-color: #f9f9f9;
  position: fixed;
  top: 0;
  z-index: 5;
  width: 100%;
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.2); }
  .navbar .links {
    margin-left: 40px; }
    .navbar .links .linkReg {
      text-decoration: none;
      margin-right: 20px;
      color: #605b5b;
      font-weight: 250;
      font-size: 1rem;
      padding-bottom: 5px; }
    .navbar .links .menu-wrap {
      display: flex;
      flex-direction: column;
      background-color: white;
      position: absolute;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
      .navbar .links .menu-wrap .menu-linkReg {
        text-decoration: none;
        color: black;
        padding: 10px 10px 10px 2px;
        font-size: 14px;
        font-weight: 300; }
      .navbar .links .menu-wrap .menu-linkReg:hover {
        background-color: #efefef; }
  .navbar .icons {
    display: flex;
    margin-right: 40px; }
    .navbar .icons .icon {
      margin-left: 15px; }

/*==========================================================
  FOOTER
==========================================================*/
.footer {
  width: 100%;
  bottom: 0;
  height: 3em;
  position: fixed;
  background-color: white;
  text-align: center;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: rgba(0, 0, 0, 0.125);
  z-index: 5; }
  .footer .name {
    display: block;
    color: #aaa;
    padding: 20px;
    font-size: 0.75rem; }

/*==========================================================
  HOME
 =========================================================*/
/*==========================================================
  ABOUT
 =========================================================*/
#about {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 160px; }
  #about .top-content {
    width: 60%;
    display: flex; }
    #about .top-content .left-box {
      width: 50%;
      margin-right: 20px; }
      #about .top-content .left-box p {
        margin-top: 10px;
        color: #222222;
        line-height: 20px;
        font-size: 1rem;
        font-weight: 300; }
      #about .top-content .left-box .name {
        font-size: 34px;
        color: #3a4145;
        margin-bottom: 25px; }
      #about .top-content .left-box .title {
        font-size: 14px;
        font-weight: 200;
        letter-spacing: 5px;
        margin-left: 2px; }
      #about .top-content .left-box .home-projects-button {
        display: flex;
        margin-top: 50px; }
        #about .top-content .left-box .home-projects-button .linkReg {
          text-decoration: none;
          color: #3a4145;
          letter-spacing: 1px;
          font-weight: 700;
          background-color: #ffea03;
          padding: 15px 30px 15px 30px;
          border-radius: 5px;
          cursor: pointer; }
        #about .top-content .left-box .home-projects-button .linkReg:hover {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
      #about .top-content .left-box .skills {
        margin-top: 30px; }
        #about .top-content .left-box .skills h2 {
          font-size: 1.5rem;
          color: #282d30; }
        #about .top-content .left-box .skills .list {
          display: flex;
          margin-top: 5px;
          flex-wrap: wrap; }
          #about .top-content .left-box .skills .list div {
            margin-right: 5px;
            margin-top: 5px;
            color: #787878;
            font-weight: 500; }
    #about .top-content .right-box {
      width: 50%; }
      #about .top-content .right-box .image-wrap {
        margin: 0 auto;
        max-width: 390px;
        min-width: 300px; }
      #about .top-content .right-box .image {
        width: 100%; }

/*==========================================================
  FORM
 =========================================================*/
.form {
  display: flex;
  justify-items: center;
  flex-direction: column;
  width: 40%;
  margin: 0 auto;
  font-size: 18px;
  margin-top: 140px; }
  .form .success-or-failure-message {
    display: flex;
    align-items: center;
    margin-bottom: 15px; }
    .form .success-or-failure-message p {
      font-size: 14px; }
  .form h1 {
    color: #222222;
    margin-bottom: 10px;
    font-weight: 500; }
  .form input {
    width: 100%;
    height: 30px;
    border: 1px solid lightgray;
    margin-bottom: 10px;
    outline: none;
    font-size: 1rem; }
  .form textarea {
    height: 150px;
    border: 1px solid lightgray;
    outline: none;
    font-size: 1.1rem; }
  .form .btn-submit {
    width: 100px;
    height: 30px;
    margin: 0 auto;
    margin-top: 10px;
    border-radius: 2px;
    background-color: #ffea03;
    color: black;
    font-size: 1rem;
    cursor: pointer; }
  .form .btn-submit:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }

/*==========================================================
  PROJECTS
 =========================================================*/
.content {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .content .project-description {
    width: 50%; }
    .content .project-description h1 {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 5px;
      margin-right: 10px; }
    .content .project-description .website-link {
      text-decoration: none; }
      .content .project-description .website-link div {
        margin-bottom: 10px;
        color: #0096cc;
        font-size: 0.85rem;
        font-weight: 300; }
      .content .project-description .website-link div:hover {
        color: #0577a1; }
    .content .project-description div {
      font-weight: 200;
      line-height: 22px; }
    .content .project-description .tech {
      line-height: 19px;
      margin-bottom: 25px;
      margin-top: 10px; }
      .content .project-description .tech h2 {
        font-weight: 350;
        font-size: 16px; }
      .content .project-description .tech p {
        font-size: 15px;
        margin-bottom: 10px; }
  .content .video-wrap {
    width: 50%;
    border: 0.9px solid #ada8a8;
    margin-bottom: 40px;
    overflow: hidden; }
    .content .video-wrap video {
      width: 100%;
      height: auto;
      background-size: cover;
      object-fit: fill;
      border-right: 0.9px solid #ada8a8; }

/*==========================================================
  RESUME
 =========================================================*/
.resume {
  margin-top: 110px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column; }
  .resume .skills-table {
    width: 50%;
    margin: 0 auto;
    font-weight: 200; }
    .resume .skills-table .cell {
      display: grid;
      text-align: left;
      grid-template-columns: repeat(2, 1fr);
      border-bottom: 3px solid #ececec;
      border-left: 3px solid #ececec;
      border-right: 3px solid #ececec; }
  .resume .email {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    font-weight: 200; }
    .resume .email .email-word {
      margin-right: 5px;
      font-weight: 500;
      text-decoration: underline; }
  .resume .resume-download {
    display: flex;
    justify-content: center; }
    .resume .resume-download a {
      margin-bottom: 10px; }
  .resume .experiences {
    width: 80%;
    margin: 0 auto;
    margin-top: 40px; }
    .resume .experiences h1 {
      font-size: 20px;
      font-weight: 600; }
    .resume .experiences .experience {
      margin-bottom: 20px;
      margin-top: 20px; }
      .resume .experiences .experience .company {
        font-size: 18px;
        margin-top: 10px;
        font-weight: 500; }
      .resume .experiences .experience .date {
        font-size: 14px;
        font-style: italic;
        margin-top: 10px;
        font-weight: 200; }
      .resume .experiences .experience .description {
        font-size: 14px;
        margin-top: 10px;
        font-weight: 200;
        line-height: 20px; }

/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

@media only screen and (min-width: 0px) and (max-width: 399px) {
  /*==========================================================
  BODY 
=========================================================*/
  body {
    margin: 0;
    font-family: BlinkMacSystemFont, Helvetica Neue, sans-serif; }
  html,
  body {
    overflow-x: hidden; }
  /*==========================================================
  NAVIGATION
==========================================================*/
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    background-color: #ececec;
    position: fixed;
    top: 0;
    z-index: 5;
    width: 100%; }
    .navbar .links {
      margin-left: 40px; }
      .navbar .links .linkReg {
        text-decoration: none;
        margin-right: 20px;
        color: black;
        font-weight: 230;
        font-size: 15px;
        padding-bottom: 5px; }
      .navbar .links .menu-wrap {
        display: flex;
        flex-direction: column;
        background-color: white;
        position: absolute;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
        .navbar .links .menu-wrap .menu-linkReg {
          text-decoration: none;
          color: black;
          padding: 10px 10px 10px 2px;
          font-size: 14px;
          font-weight: 300; }
        .navbar .links .menu-wrap .menu-linkReg:hover {
          background-color: #efefef; }
    .navbar .icons {
      display: flex;
      margin-right: 40px; }
      .navbar .icons .icon {
        margin-left: 20px; }
  /*==========================================================
  HOME
 =========================================================*/
  /*==========================================================
  ABOUT
 =========================================================*/
  #about {
    display: flex;
    align-items: center;
    margin-top: 140px; }
    #about .top-content {
      display: flex;
      flex-wrap: wrap;
      width: 80%; }
      #about .top-content .left-box {
        width: 90%;
        margin-right: 0px;
        position: relative;
        margin-bottom: 20px; }
        #about .top-content .left-box p {
          margin-top: 10px;
          color: #222222;
          line-height: 20px;
          font-size: 14px;
          font-weight: 300; }
        #about .top-content .left-box .name {
          font-size: 28px; }
        #about .top-content .left-box .title {
          font-size: 12px;
          font-weight: 200;
          letter-spacing: 5px;
          margin-left: 2px; }
        #about .top-content .left-box .home-projects-button {
          display: flex;
          margin-top: 30px; }
          #about .top-content .left-box .home-projects-button .linkReg {
            text-decoration: none;
            color: black;
            letter-spacing: 1px;
            background-color: #ffea03;
            padding: 10px 25px 10px 25px;
            border-radius: 2px;
            cursor: pointer; }
          #about .top-content .left-box .home-projects-button .linkReg:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
        #about .top-content .left-box .skills {
          margin-top: 20px; }
          #about .top-content .left-box .skills .list {
            display: flex;
            margin-top: 5px; }
            #about .top-content .left-box .skills .list div {
              margin-right: 5px;
              color: #787878;
              font-weight: 500; }
      #about .top-content .right-box {
        width: 0%;
        margin-right: 0; }
        #about .top-content .right-box .image-wrap {
          width: 80%; }
        #about .top-content .right-box .image {
          width: 100%;
          max-width: 90px;
          min-width: 90px;
          margin-left: -40px;
          margin-top: -65px;
          border-radius: 50%; }
  /*==========================================================
  FORM
 =========================================================*/
  .form {
    display: flex;
    justify-items: center;
    flex-direction: column;
    width: 70%;
    margin: 0 auto;
    font-size: 18px;
    margin-top: 140px; }
    .form .success-or-failure-message {
      display: flex;
      align-items: center;
      margin-bottom: 15px; }
      .form .success-or-failure-message p {
        font-size: 14px; }
    .form h1 {
      color: #222222;
      margin-bottom: 10px;
      font-weight: 500; }
    .form input {
      width: 100%;
      height: 20px;
      border: 1px solid lightgray;
      margin-bottom: 10px;
      outline: none;
      font-size: 14px; }
    .form textarea {
      height: 150px;
      border: 1px solid lightgray;
      outline: none;
      font-size: 14px; }
    .form .btn-submit {
      width: 100px;
      height: 30px;
      margin: 0 auto;
      margin-top: 10px;
      border-radius: 2px;
      background-color: #ffea03;
      color: black;
      font-size: 14px;
      cursor: pointer; }
    .form .btn-submit:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
  /*==========================================================
  PROJECTS
 =========================================================*/
  .content {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .content .project-description {
      width: 70%; }
      .content .project-description h1 {
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 0px;
        margin-right: 10px; }
      .content .project-description div {
        font-weight: 200;
        margin-bottom: 10px;
        line-height: 22px;
        font-size: 14px; }
      .content .project-description .tech {
        line-height: 19px;
        margin-bottom: 25px; }
        .content .project-description .tech h2 {
          font-weight: 350;
          font-size: 14px; }
        .content .project-description .tech p {
          font-size: 13px;
          margin-bottom: 10px; }
    .content .video-wrap {
      width: 70%;
      border: 0.9px solid #ada8a8;
      margin-bottom: 40px; }
      .content .video-wrap video {
        width: 100%; }
  /*==========================================================
  RESUME
 =========================================================*/
  .resume {
    margin-top: 110px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column; }
    .resume .skills-table {
      width: 80%;
      margin: 0 auto;
      font-weight: 200;
      line-height: 25px;
      font-size: 14px; }
      .resume .skills-table .cell {
        display: grid;
        text-align: left;
        grid-template-columns: repeat(2, 1fr);
        border-bottom: 3px solid #ececec;
        border-left: 3px solid #ececec;
        border-right: 3px solid #ececec; }
    .resume .email {
      display: flex;
      justify-content: center;
      margin-top: 15px;
      font-weight: 200; }
      .resume .email .email-word {
        margin-right: 5px;
        font-weight: 500;
        text-decoration: underline; }
    .resume .resume-download {
      display: flex;
      justify-content: center; }
      .resume .resume-download a {
        margin-bottom: 10px; }
    .resume .experiences {
      width: 80%;
      margin: 0 auto;
      margin-top: 40px; }
      .resume .experiences h1 {
        font-size: 18px;
        font-weight: 600; }
      .resume .experiences .experience {
        margin-bottom: 20px;
        margin-top: 20px; }
        .resume .experiences .experience .company {
          font-size: 16px;
          margin-top: 10px;
          font-weight: 500; }
        .resume .experiences .experience .date {
          font-size: 12px;
          font-style: italic;
          margin-top: 10px;
          font-weight: 200;
          line-height: 20px; }
        .resume .experiences .experience .description {
          font-size: 12px;
          margin-top: 10px;
          font-weight: 200;
          line-height: 20px; } }

/* end of media query */
@media only screen and (min-width: 400px) and (max-width: 700px) {
  /*==========================================================
  BODY 
=========================================================*/
  body {
    margin: 0;
    font-family: BlinkMacSystemFont, Helvetica Neue, sans-serif; }
  html,
  body {
    overflow-x: hidden; }
  /*==========================================================
  NAVIGATION
==========================================================*/
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    background-color: #ececec;
    position: fixed;
    top: 0;
    z-index: 5;
    width: 100%; }
    .navbar .links {
      margin-left: 40px; }
      .navbar .links .linkReg {
        text-decoration: none;
        margin-right: 20px;
        color: black;
        font-weight: 230;
        font-size: 15px;
        padding-bottom: 5px; }
      .navbar .links .menu-wrap {
        display: flex;
        flex-direction: column;
        background-color: white;
        position: absolute;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
        .navbar .links .menu-wrap .menu-linkReg {
          text-decoration: none;
          color: black;
          padding: 10px 10px 10px 2px;
          font-size: 14px;
          font-weight: 300; }
        .navbar .links .menu-wrap .menu-linkReg:hover {
          background-color: #efefef; }
    .navbar .icons {
      display: flex;
      margin-right: 40px; }
      .navbar .icons .icon {
        margin-left: 20px; }
  /*==========================================================
  HOME
 =========================================================*/
  /*==========================================================
  ABOUT
 =========================================================*/
  #about {
    display: flex;
    align-items: center;
    margin-top: 140px; }
    #about .top-content {
      display: flex;
      flex-wrap: wrap;
      width: 80%; }
      #about .top-content .left-box {
        width: 90%;
        margin-right: 0px;
        position: relative; }
        #about .top-content .left-box p {
          margin-top: 10px;
          color: #222222;
          line-height: 20px;
          font-size: 14px;
          font-weight: 300; }
        #about .top-content .left-box .name {
          font-size: 28px; }
        #about .top-content .left-box .title {
          font-size: 12px;
          font-weight: 200;
          letter-spacing: 5px;
          margin-left: 2px; }
        #about .top-content .left-box .home-projects-button {
          display: flex;
          margin-top: 30px; }
          #about .top-content .left-box .home-projects-button .linkReg {
            text-decoration: none;
            color: black;
            letter-spacing: 1px;
            background-color: #ffea03;
            padding: 15px 30px 15px 30px;
            border-radius: 2px;
            cursor: pointer; }
          #about .top-content .left-box .home-projects-button .linkReg:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
        #about .top-content .left-box .skills {
          margin-top: 20px; }
          #about .top-content .left-box .skills .list {
            display: flex;
            margin-top: 5px; }
            #about .top-content .left-box .skills .list div {
              margin-right: 5px;
              color: #787878;
              font-weight: 500; }
      #about .top-content .right-box {
        width: 0%;
        margin-right: 0; }
        #about .top-content .right-box .image-wrap {
          width: 80%; }
        #about .top-content .right-box .image {
          width: 100%;
          max-width: 110px;
          min-width: 110px;
          position: absolute;
          margin-left: -60px;
          margin-top: -60px;
          border-radius: 50%; }
  /*==========================================================
  FORM
 =========================================================*/
  .form {
    display: flex;
    justify-items: center;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    font-size: 18px;
    margin-top: 140px; }
    .form .success-or-failure-message {
      display: flex;
      align-items: center;
      margin-bottom: 15px; }
      .form .success-or-failure-message p {
        font-size: 14px; }
    .form h1 {
      color: #222222;
      margin-bottom: 10px;
      font-weight: 500; }
    .form input {
      width: 100%;
      height: 20px;
      border: 1px solid lightgray;
      margin-bottom: 10px;
      outline: none;
      font-size: 14px; }
    .form textarea {
      height: 150px;
      border: 1px solid lightgray;
      outline: none;
      font-size: 14px; }
    .form .btn-submit {
      width: 100px;
      height: 30px;
      margin: 0 auto;
      margin-top: 10px;
      border-radius: 2px;
      background-color: #ffea03;
      color: black;
      font-size: 14px;
      cursor: pointer; }
    .form .btn-submit:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
  /*==========================================================
  PROJECTS
 =========================================================*/
  .content {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .content .project-description {
      width: 70%; }
      .content .project-description h1 {
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 0px;
        margin-right: 10px; }
      .content .project-description div {
        font-weight: 200;
        margin-bottom: 10px;
        line-height: 22px;
        font-size: 14px; }
      .content .project-description .tech {
        line-height: 19px;
        margin-bottom: 25px; }
        .content .project-description .tech h2 {
          font-weight: 350;
          font-size: 14px; }
        .content .project-description .tech p {
          font-size: 13px;
          margin-bottom: 10px; }
    .content .video-wrap {
      width: 70%;
      border: 0.9px solid #ada8a8;
      margin-bottom: 40px; }
      .content .video-wrap video {
        width: 100%; }
  /*==========================================================
  RESUME
 =========================================================*/
  .resume {
    margin-top: 110px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column; }
    .resume .skills-table {
      width: 80%;
      margin: 0 auto;
      font-weight: 200;
      line-height: 25px; }
      .resume .skills-table .cell {
        display: grid;
        text-align: left;
        grid-template-columns: repeat(2, 1fr);
        border-bottom: 3px solid #ececec;
        border-left: 3px solid #ececec;
        border-right: 3px solid #ececec; }
    .resume .email {
      display: flex;
      justify-content: center;
      margin-top: 15px;
      font-weight: 200; }
      .resume .email .email-word {
        margin-right: 5px;
        font-weight: 500;
        text-decoration: underline; }
    .resume .resume-download {
      display: flex;
      justify-content: center; }
      .resume .resume-download a {
        margin-bottom: 10px; }
    .resume .experiences {
      width: 80%;
      margin: 0 auto;
      margin-top: 40px; }
      .resume .experiences h1 {
        font-size: 22px;
        font-weight: 600; }
      .resume .experiences .experience {
        margin-bottom: 20px;
        margin-top: 20px; }
        .resume .experiences .experience .company {
          font-size: 16px;
          margin-top: 10px;
          font-weight: 500; }
        .resume .experiences .experience .date {
          font-size: 14px;
          font-style: italic;
          margin-top: 10px;
          font-weight: 200;
          line-height: 20px; }
        .resume .experiences .experience .description {
          font-size: 14px;
          margin-top: 10px;
          font-weight: 200;
          line-height: 20px; } }

/* end of media query */
/* end of media query */

